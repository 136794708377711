import { useEffect } from 'react';
import { Spinner } from 'Shared/spinner';
import { getLastViewedPage, refreshTokenFlow, saveTokens } from '@/utils';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/react';

export const RefreshToken = ({ children }: any) => {
  const refreshTokens = async () => {
    const tokens = await refreshTokenFlow();
    if (tokens !== null) {
      saveTokens(tokens);
      const lastVisitedPage = getLastViewedPage();
      window.location.href = lastVisitedPage;
      Sentry.captureMessage('Refresh Token Successfully');
    }
  };
  useEffect(() => {
    refreshTokens();
  }, []);

  return <Spinner />;
};

export default RefreshToken;
